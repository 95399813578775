import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { getDocumentDetailsPerDocument } from "../utils/api";
import WebViewer from "@pdftron/webviewer";

const DocumentViewer = ({ document, searchText = "", property_id }) => {
  const [documentDetails, setDocumentDetails] = useState(document);
  const [isWebViewerReady, setIsWebViewerReady] = useState(false);
  const viewer = useRef(null);
  const webViewerInstance = useRef(null);

  useEffect(() => {
    const fetchDocumentDetails = async () => {
      try {
        const data = await getDocumentDetailsPerDocument(
          property_id,
          document.document_id
        );
        setDocumentDetails(data);
        setIsWebViewerReady(true);
      } catch (error) {
        console.error("Error fetching document details:", error);
      }
    };

    fetchDocumentDetails();
  }, [property_id, document.document_id]);

  useEffect(() => {
    if (
      isWebViewerReady &&
      documentDetails &&
      viewer.current &&
      !webViewerInstance.current
    ) {
      console.log("Initializing WebViewer");
      console.log("Document URL:", documentDetails.signed_url);

      WebViewer(
        {
          path: "/webviewer/lib",
          initialDoc: documentDetails.signed_url,
          initialZoom: 1,
        },
        viewer.current
      )
        .then(async (instance) => {
          console.log("WebViewer initialized");
          webViewerInstance.current = instance;
          const { documentViewer, annotationManager } = instance.Core;

          documentViewer.addEventListener("documentLoaded", () => {
            console.log("Document loaded");

            instance.UI.setFitMode(instance.UI.FitMode.FitWidth);

            if (typeof searchText === "string" && searchText.trim() !== "") {
              console.log("Searching for text:", searchText);

              instance.UI.searchTextFull(searchText, {
                caseSensitive: false,
                wholeWord: false,
                wildcard: false,
                regex: true,
                searchUp: false,
                ambientString: true,
              }).then((results) => {
                console.log("Search results:", results);
                if (results.length > 0) {
                  const firstResult = results[0];
                  console.log("First result:", firstResult);

                  // Highlight the text
                  instance.UI.addSearchListener(
                    (searchTerm, options, results) => {
                      annotationManager.drawAnnotationsFromSearchResults(
                        results
                      );
                    }
                  );

                  // Scroll to the result
                  documentViewer.setActiveSearchResult(firstResult);

                  console.log("Highlighted and scrolled to search result");
                } else {
                  console.log("No search results found");
                }
              });
            }
          });
        })
        .catch((error) => {
          console.error("WebViewer initialization failed:", error);
        });
    }
  }, [isWebViewerReady, documentDetails]);

  if (!documentDetails || !isWebViewerReady) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {documentDetails.document_name}
      </Typography>
      <div ref={viewer} style={{ height: "600px" }}></div>
    </Box>
  );
};

export default DocumentViewer;
