import axios from "axios";
import { getToken, removeToken } from "./auth";
import { refreshToken } from "./refreshToken";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshed = await refreshToken();
      if (refreshed) {
        return api(originalRequest);
      } else {
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

export default api;

// fetch dashboard data
export const fetchDashboardData = async () => {
  return api.get("/dashboard");
};

// Fetch all properties
export const getAllProperties = async () => {
  try {
    const response = await api.get("/get-all-properties");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch properties:", error);
    throw error;
  }
};

// Create a new property
export const createProperty = async (propertyData) => {
  try {
    const response = await api.post("/create-property", propertyData);
    return response.data;
  } catch (error) {
    console.error("Failed to create property:", error);
    throw error;
  }
};

// Delete a property
export const deleteProperty = async (propertyId) => {
  try {
    const response = await api.delete(`/delete-property/${propertyId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to delete property:", error);
    throw error;
  }
};

// Create a user
export const createUser = async (userData) => {
  try {
    const response = await api.post("/create-user", userData);
    return response.data;
  } catch (error) {
    console.error("Failed to create user:", error);
    throw error;
  }
};

// Get user profile
export const getUserProfile = async () => {
  try {
    const response = await api.get("/get-user-profile");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch user profile:", error);
    throw error;
  }
};

// Fetch all documents for a specific property
export const getAllDocuments = async (propertyId) => {
  try {
    const response = await api.get(`/documents/${propertyId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch documents:", error);
    throw error;
  }
};

// Upload files for a specific property
export const uploadFiles = async (propertyId, files) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  try {
    const response = await api.post(
      `/documents/upload?property_id=${propertyId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to upload files:", error);
    throw error;
  }
};

export const getDocumentDetails = async (property_id, document_id) => {
  const response = await api.get(`/documents/${property_id}`);
  const document = response.data.documents.find(
    (doc) => doc.document_id === document_id
  );
  return document;
};

export const getAnalysisResults = async (property_id) => {
  const response = await api.get(`/analysis/${property_id}`);
  return response.data;
};

export const getPropertyDetails = async (property_id) => {
  const response = await api.get(`/get-property/${property_id}`);
  return response.data;
};

export const startAnalysisV2 = async (propertyId) => {
  try {
    const response = await api.post(`/analysisv2/${propertyId}/start`);
    return response.data;
  } catch (error) {
    console.error("Failed to start analysis V2:", error);
    throw error;
  }
};

export const getAnalysisResultsV2 = async (propertyId) => {
  try {
    const response = await api.get(`/analysisv2/${propertyId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch analysis V2 results:", error);
    throw error;
  }
};

export const getDocumentDetailsPerDocument = async (
  property_id,
  document_id
) => {
  try {
    const response = await api.get(`/documents/${property_id}/${document_id}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch document details:", error);
    throw error;
  }
};
