// utils/refreshToken.js

import { supabase } from "./auth";
import { setToken, removeToken } from "./auth";

export const refreshToken = async () => {
  const { data, error } = await supabase.auth.refreshSession();
  if (data.session) {
    setToken(data.session.access_token);
    return true;
  } else {
    removeToken();
    return false;
  }
};
