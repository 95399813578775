import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  CircularProgress,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllDocuments,
  uploadFiles,
  getAnalysisResults,
  getPropertyDetails,
} from "../utils/api";
import AnalysisComponent from "./AnalysisComponent";
import DocumentViewer from "./DocumentViewer";
import PropertyChat from "./PropertyChat";
import AnalysisComponentV2 from "./AnalysisComponentV2";

const PropertyDashboard = () => {
  const navigate = useNavigate();
  const { property_id } = useParams();
  const [documents, setDocuments] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [analysisResults, setAnalysisResults] = useState({});
  const [overallCommentary, setOverallCommentary] = useState("");
  const [analysisStatus, setAnalysisStatus] = useState("pending");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState({
    name: "",
    address: "",
  });
  const [isPropertyLoading, setIsPropertyLoading] = useState(true);

  useEffect(() => {
    const pollInterval = 3000; // 3 seconds

    const pollAnalysisResults = async () => {
      try {
        const analysisData = await getAnalysisResults(property_id);
        setAnalysisResults(analysisData.analysis_results);
        setOverallCommentary(analysisData.overall_commentary);
        setAnalysisStatus(analysisData.status);

        // Stop polling if analysis status is "completed"
        if (analysisData.status === "completed") {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error polling analysis results:", error);
      }
    };

    const intervalId = setInterval(pollAnalysisResults, pollInterval);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [property_id]);

  useEffect(() => {
    const fetchData = async () => {
      setIsPropertyLoading(true);
      try {
        const [documentsData, analysisData, propertyData] = await Promise.all([
          getAllDocuments(property_id),
          getAnalysisResults(property_id),
          getPropertyDetails(property_id),
        ]);

        setDocuments(documentsData.documents);
        setAnalysisResults(analysisData.analysis_results);
        setOverallCommentary(analysisData.overall_commentary);
        setAnalysisStatus(analysisData.status);
        setPropertyDetails({
          name: propertyData.name,
          address: propertyData.address,
        });

        // Stop polling if analysis status is no longer "pending"
        if (analysisData.status !== "pending") {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsPropertyLoading(false);
      }
    };

    fetchData();
  }, [property_id, analysisStatus]);

  const handleDocumentClick = (document) => {
    setSelectedDocument(document);
    setOpenDialog(true);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    try {
      await uploadFiles(property_id, files);
      const data = await getAllDocuments(property_id);
      setDocuments(data.documents);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDocument(null);
  };

  if (isPropertyLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  console.log("Analysis status:", analysisStatus);

  return (
    <Box
      sx={{
        height: "95vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ color: "black" }}>
        {propertyDetails.name}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {propertyDetails.address}
      </Typography>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="dashboard tabs"
      >
        <Tab label="Analysis" />
        <Tab label="Files" />
        <Tab label="Chat" />
        <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              Analysis v2
              <Chip label="beta" color="primary" size="small" sx={{ ml: 1 }} />
            </Box>
          }
        />
      </Tabs>

      {tabValue === 0 &&
        (analysisStatus === "pending" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : analysisStatus === "upload_documents" ? (
          <div>
            <p className="text-center mt-4">
              Please upload a document to analyze it
            </p>
          </div>
        ) : (
          <AnalysisComponent
            analysisResults={analysisResults}
            overallCommentary={overallCommentary}
            analysisStatus={analysisStatus}
          />
        ))}

      {tabValue === 1 && (
        <Box sx={{ marginBottom: 4, marginTop: 2 }}>
          <Button
            variant="contained"
            component="label"
            sx={{ marginBottom: 2, backgroundColor: "#2C6EC3" }}
          >
            Upload File(s)
            <input type="file" hidden multiple onChange={handleFileUpload} />
          </Button>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, backgroundColor: "white" }}
              aria-label="Documents table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name of Document</TableCell>
                  <TableCell>Size (MB)</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((doc) => (
                  <TableRow
                    key={doc.document_id}
                    hover
                    onClick={() => handleDocumentClick(doc)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{doc.document_name}</TableCell>
                    <TableCell>
                      {(doc.document_metadata.size / (1024 * 1024)).toFixed(2)}{" "}
                      MB
                    </TableCell>
                    <TableCell>
                      {new Date(doc.created_at).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {tabValue === 2 && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            overflow: "hidden",
          }}
        >
          <PropertyChat propertyId={property_id} />
        </Box>
      )}

      {tabValue === 3 && <AnalysisComponentV2 />}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          Document Analysis
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedDocument && (
            <DocumentViewer
              document={selectedDocument}
              property_id={property_id}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PropertyDashboard;
