import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";

const PropertyChat = ({ propertyId, authToken }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const websocket = useRef(null);
  const ongoingMessageRef = useRef("");
  const messagesEndRef = useRef(null);

  // Function to scroll to the bottom of the messages area
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll to bottom every time messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Open WebSocket connection
    websocket.current = new WebSocket(
      `wss://agentpalsbackend.com/chat/${propertyId}`
    );

    websocket.current.onopen = () => {
      console.log("WebSocket connection opened");
    };

    websocket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("WebSocket message received:", data);
      if (data.type === "stream") {
        // Append the incoming content to the ongoing message
        ongoingMessageRef.current += data.content;
        setMessages((prevMessages) => {
          // Replace the last message with the updated ongoing message
          const updatedMessages = [...prevMessages];
          if (
            updatedMessages.length > 0 &&
            updatedMessages[updatedMessages.length - 1].sender === "bot"
          ) {
            updatedMessages[updatedMessages.length - 1].content =
              ongoingMessageRef.current;
          } else {
            updatedMessages.push({
              content: ongoingMessageRef.current,
              sender: "bot",
            });
          }
          return updatedMessages;
        });
      } else if (data.type === "end") {
        // Finalize the message
        ongoingMessageRef.current = "";
      }
      // if (data.type === "stream" || data.type === "end") {
      //   setMessages((prevMessages) => [
      //     ...prevMessages,
      //     { content: data.content || data.full_response, sender: "bot" },
      //   ]);
      // }
    };

    websocket.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    websocket.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      websocket.current.close();
    };
  }, [propertyId]);

  const sendMessage = () => {
    if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
      console.log("Sending message:", input);
      websocket.current.send(input);
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: input, sender: "user" },
      ]);
      setInput("");
    }
  };

  console.log(messages);

  return (
    <div className="chat-container">
      <div className="messages-area">
        {messages.map((message, index) => (
          <div
            className={`flex w-full ${
              message.sender === "user" ? "justify-end" : "justify-start"
            } w-full`}
          >
            <div className="inline-block max-w-[80%]">
              <div
                key={index}
                className={`message ${
                  message.sender === "user" ? "user-message" : "bot-message"
                }`}
              >
                <ReactMarkdown>{message.content}</ReactMarkdown>
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-area">
        <input
          className="input-box"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => (e.key === "Enter" ? sendMessage() : null)}
          placeholder="Type a message..."
        />
        <button className="send-button" onClick={sendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};

export default PropertyChat;
