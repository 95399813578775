import React, { useState, useEffect } from "react";
import { fetchDashboardData, getAllProperties } from "../utils/api";
import Sidebar from "../components/DashboardSidebar";
import { Box } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [error, setError] = useState(null);
  const [propertiesData, setPropertyData] = useState([]);
  const { property_id } = useParams();

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const dashboardResponse = await fetchDashboardData();
        setDashboardData(dashboardResponse.data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setError("Failed to fetch dashboard data");
      }
    };

    getDashboardData();
  }, []);

  if (error) return <div>{error}</div>;
  if (!dashboardData) return <div>Loading...</div>;

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: "white",
        minHeight: "100vh",
      }}
    >
      <Sidebar
        properties={propertiesData}
        onPropertySelect={() => {}}
        className="z-50"
      />
      <Box
        style={{
          marginLeft: 300,
          padding: "20px",
          width: "100%",
        }}
      >
        {property_id ? (
          <Outlet />
        ) : (
          <div className="text-black mt-4 font-sans text-4xl text-center">
            <div>Select a property from sidebar to view its analysis</div>
            <div>or add a new property by clicking the + button</div>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
