import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactMarkdown from "react-markdown";
import {
  getAnalysisResultsV2,
  getDocumentDetailsPerDocument,
  startAnalysisV2,
} from "../utils/api";
import { generateSimpleRegex } from "../utils/regexUtils";
import DocumentViewer from "./DocumentViewer";

// Define color coding for flags
const flagColors = {
  red_flags: "red",
  yellow_flags: "orange",
  green_flags: "green",
};

const AnalysisComponentV2 = () => {
  const { property_id } = useParams();
  const [analysisData, setAnalysisData] = useState({
    analysisResults: null,
    overallCommentary: "",
    analysisStatus: "pending",
  });
  const [expanded, setExpanded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedRegexMatch, setSelectedRegexMatch] = useState("");

  const handleCitationClick = async (flagData) => {
    try {
      const documentDetails = await getDocumentDetailsPerDocument(
        property_id,
        flagData.citation.document_id
      );
      setSelectedDocument(documentDetails);
      // Generate regex from matched_text
      const regex = generateSimpleRegex(flagData.matched_text);
      // Convert RegExp to string, removing the leading/trailing slashes and flags
      const regexString = regex.toString().slice(1, -2);
      setSelectedRegexMatch(regexString);
      console.log();
      setOpenDialog(true);
    } catch (error) {
      console.error("Failed to fetch document details:", error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const startAnalysis = async () => {
    try {
      await startAnalysisV2(property_id);
      setAnalysisData((prevState) => ({
        ...prevState,
        analysisStatus: "pending",
      }));
    } catch (error) {
      console.error("Failed to start analysis:", error);
    }
  };

  const fetchAnalysisData = async () => {
    try {
      const response = await getAnalysisResultsV2(property_id);
      console.log("API Response:", response);
      const { analysis_results, overall_commentary, status } = response;
      console.log("Analysis Status:", status);
      setAnalysisData({
        analysisResults: analysis_results,
        overallCommentary: overall_commentary,
        analysisStatus: status,
      });
      console.log("State updated:", {
        analysisResults: analysis_results,
        overallCommentary: overall_commentary,
        analysisStatus: status,
      });
    } catch (error) {
      console.error("Failed to fetch analysis data:", error);
      setAnalysisData((prevState) => ({
        ...prevState,
        analysisStatus: "error",
      }));
    }
  };

  useEffect(() => {
    fetchAnalysisData();

    if (analysisData.analysisStatus === "pending") {
      const pollInterval = setInterval(() => {
        fetchAnalysisData();
      }, 5000); // Poll every 5 seconds

      return () => clearInterval(pollInterval);
    }
  }, [property_id, analysisData.analysisStatus]);

  const parsedAnalysisResults = parseAnalysisResults(
    analysisData.analysisResults
  );

  const handleAccordionChange = (component) => (event, isExpanded) => {
    setExpanded(isExpanded ? component : false);
  };

  return (
    <Box sx={{ marginBottom: 4, marginTop: 2 }}>
      {(analysisData.analysisStatus === "error" ||
        analysisData.analysisStatus === "upload_documents") && (
        <Button variant="contained" onClick={startAnalysis}>
          Start Analysis
        </Button>
      )}

      {analysisData.analysisStatus === "pending" && (
        <Typography variant="body2">
          Analysis is in progress. Please wait...
        </Typography>
      )}
      {analysisData.analysisStatus === "completed" &&
        analysisData.analysisResults && (
          <>
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 2,
                padding: 2,
                marginTop: 3,
              }}
            >
              <Typography variant="h4" gutterBottom>
                Detailed Component Analysis Report
              </Typography>
              <Grid container spacing={2}>
                {Object.keys(parsedAnalysisResults).map((component) => (
                  <Grid item xs={12} key={component}>
                    <Accordion
                      expanded={expanded === component}
                      onChange={handleAccordionChange(component)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${component}-content`}
                        id={`${component}-header`}
                      >
                        <Typography variant="h6" gutterBottom>
                          {component}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {["red_flags", "yellow_flags", "green_flags"].map(
                          (flag) => (
                            <Box
                              key={flag}
                              border={1}
                              padding={2}
                              borderRadius={2}
                              sx={{
                                borderColor: flagColors[flag],
                                marginBottom: 2,
                                backgroundColor: `${flagColors[flag]}20`,
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{ color: flagColors[flag] }}
                                gutterBottom
                              >
                                {flag.replace("_", " ").toUpperCase()}
                              </Typography>
                              {parsedAnalysisResults[component][flag]?.map(
                                (flagData, index) => (
                                  <Box
                                    key={index}
                                    display="flex"
                                    alignItems="flex-start"
                                    sx={{ marginBottom: 1 }}
                                  >
                                    <Box
                                      component="span"
                                      sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: "50%",
                                        backgroundColor: flagColors[flag],
                                        marginRight: 1.5,
                                        flexShrink: 0, // Ensures dot size is consistent
                                        marginTop: "6px", // Adjusted for alignment with text
                                      }}
                                    />
                                    <Box sx={{ flexGrow: 1 }}>
                                      <ReactMarkdown>
                                        {flagData.text}
                                      </ReactMarkdown>
                                      {flagData.citation && (
                                        <Typography
                                          variant="body2"
                                          sx={{ marginTop: 0.5 }}
                                        >
                                          <Link
                                            href="#"
                                            underline="hover"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCitationClick(flagData);
                                            }}
                                          >
                                            [source]
                                          </Link>
                                        </Typography>
                                      )}
                                    </Box>
                                  </Box>
                                )
                              )}
                            </Box>
                          )
                        )}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Document Sources</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {parsedAnalysisResults[component].sources?.map(
                              (source, index) => (
                                <Typography key={index} variant="body2">
                                  {`Source: ${source.source}, Page: ${source.page_number}`}
                                </Typography>
                              )
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 2,
                padding: 2,
                marginTop: 3,
              }}
            >
              <Typography variant="h4" gutterBottom>
                Overall Property Analysis
              </Typography>
              <ReactMarkdown>{analysisData.overallCommentary}</ReactMarkdown>
            </Box>
          </>
        )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          Document Analysis
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedDocument && (
            <DocumentViewer
              document={selectedDocument}
              searchText={selectedRegexMatch}
              property_id={property_id}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const parseAnalysisResults = (results) => {
  if (!results || typeof results !== "object") {
    return {};
  }

  const parsedResults = {};

  for (const [component, data] of Object.entries(results)) {
    parsedResults[component] = {
      red_flags: data.response.red_flags || [],
      yellow_flags: data.response.yellow_flags || [],
      green_flags: data.response.green_flags || [],
      sources: data.source_documents || [],
    };
  }

  return parsedResults;
};

export default AnalysisComponentV2;
