import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactMarkdown from "react-markdown";

// Define color coding for flags
const flagColors = {
  red_flags: "red",
  yellow_flags: "orange",
  green_flags: "green",
};

const AnalysisComponent = ({
  analysisResults,
  overallCommentary,
  analysisStatus,
}) => {
  const [expanded, setExpanded] = useState(false);
  const parsedAnalysisResults = parseAnalysisResults(analysisResults);

  const handleAccordionChange = (component) => (event, isExpanded) => {
    setExpanded(isExpanded ? component : false);
  };

  return (
    <Box sx={{ marginBottom: 4, marginTop: 2 }}>
      {analysisStatus !== "completed" || !analysisResults ? (
        <Typography variant="body2">
          Analysis is {analysisStatus}. Please wait...
        </Typography>
      ) : (
        <>
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
              padding: 2,
              marginTop: 3,
            }}
          >
            <Typography variant="h4" gutterBottom>
              Detailed Component Analysis Report
            </Typography>
            <Grid container spacing={2}>
              {Object.keys(parsedAnalysisResults).map((component) => (
                <Grid item xs={12} key={component}>
                  <Accordion
                    expanded={expanded === component}
                    onChange={handleAccordionChange(component)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${component}-content`}
                      id={`${component}-header`}
                    >
                      <Typography variant="h6" gutterBottom>
                        {component}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {["red_flags", "yellow_flags", "green_flags"].map(
                        (flag) => (
                          <Box
                            key={flag}
                            border={1}
                            padding={2}
                            borderRadius={2}
                            sx={{
                              borderColor: flagColors[flag],
                              marginBottom: 2,
                              backgroundColor: `${flagColors[flag]}20`,
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ color: flagColors[flag] }}
                              gutterBottom
                            >
                              {flag.replace("_", " ").toUpperCase()}
                            </Typography>
                            {parsedAnalysisResults[component][flag]?.map(
                              (sentence, index) => (
                                <Box
                                  key={index}
                                  display="flex"
                                  alignItems="flex-start"
                                  sx={{ marginBottom: 1 }}
                                >
                                  <Box
                                    component="span"
                                    sx={{
                                      width: 8,
                                      height: 8,
                                      borderRadius: "50%",
                                      backgroundColor: flagColors[flag],
                                      marginRight: 1.5,
                                      flexShrink: 0, // Ensures dot size is consistent
                                      marginTop: "6px", // Adjusted for alignment with text
                                    }}
                                  />
                                  <Box sx={{ flexGrow: 1 }}>
                                    <ReactMarkdown>{sentence}</ReactMarkdown>
                                  </Box>
                                </Box>
                              )
                            )}
                          </Box>
                        )
                      )}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Sources</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {parsedAnalysisResults[component].sources?.map(
                            (source, index) => (
                              <Typography key={index} variant="body2">
                                {`Source: ${source.source}, Page: ${source.page_number}`}
                              </Typography>
                            )
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
              padding: 2,
              marginTop: 3,
            }}
          >
            <Typography variant="h4" gutterBottom>
              Overall Property Analysis
            </Typography>
            <ReactMarkdown>{overallCommentary}</ReactMarkdown>
          </Box>
        </>
      )}
    </Box>
  );
};

const parseAnalysisResults = (results) => {
  if (!results || typeof results !== "object") {
    return {};
  }

  const parsedResults = {};

  for (const [component, data] of Object.entries(results)) {
    parsedResults[component] = {
      red_flags: data.response.red_flags || [],
      yellow_flags: data.response.yellow_flags || [],
      green_flags: data.response.green_flags || [],
      sources: data.source_documents || [],
    };
  }

  return parsedResults;
};

export default AnalysisComponent;
