// src/hooks/useAuth.js

import { useState, useEffect } from "react";
import { getToken, setToken, removeToken, supabase } from "../utils/auth";

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const refreshSession = async () => {
    const { data, error } = await supabase.auth.refreshSession();
    if (data.session) {
      setToken(data.session.access_token);
      setIsAuthenticated(true);
    } else {
      removeToken();
      setIsAuthenticated(false);
    }
    return !error;
  };

  const checkAuth = async () => {
    const token = getToken();
    if (token) {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        setIsAuthenticated(true);
      } else {
        const refreshed = await refreshSession();
        if (!refreshed) {
          removeToken();
          setIsAuthenticated(false);
        }
      }
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return { isAuthenticated, setIsAuthenticated, isLoading, refreshSession };
};
