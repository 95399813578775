const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const generateSimpleRegex = (text) => {
  // Remove most special characters except hyphens and spaces
  const cleanedText = text.replace(/[^\w\s-]/g, "");

  // Split into words, preserving hyphens
  const words = cleanedText.match(/\b[\w-]+\b/g) || [];

  // Create a pattern where each word or hyphenated term is followed by a word boundary (\b),
  // except the last one
  let pattern;
  if (words.length > 1) {
    pattern =
      words
        .slice(0, -1)
        .map((word) => `${escapeRegExp(word)}\\b.*?`)
        .join("") + escapeRegExp(words[words.length - 1]);
  } else if (words.length === 1) {
    pattern = escapeRegExp(words[0]);
  } else {
    pattern = "";
  }

  // Make the entire regex case-insensitive and allow for flexible whitespace
  return new RegExp(`${pattern}`, "i");
};
