import React from "react";
import { Box, Typography } from "@mui/material";

const LogoWithText = () => {
  return (
    <Box className="flex items-center justify-center">
      <img
        src="/assets/images/new_logo.png"
        className="m-2 rounded-md"
        alt="Maverick Logo"
        width={50}
        height={50}
      />
      <Typography variant="h5" className="leading-none">
        AgentPals
      </Typography>
    </Box>
  );
};

export default LogoWithText;
