import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { supabase } from "../utils/auth";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAllProperties, createProperty, deleteProperty } from "../utils/api";
import LogoWithText from "./LogoWithText";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";

const SidebarContainer = styled(Box)(({ expanded }) => ({
  width: expanded ? 300 : 150,
  transition: "width 0.3s ease",
  height: "100vh",
  position: "fixed",
  left: 0,
  top: 0,
  backgroundColor: "#282c34",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  padding: expanded ? "16px 8px" : "8px",
}));

const SidebarItem = styled(ListItem)(({ expanded }) => ({
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: expanded ? "5px 16px" : "10px 8px",
  whiteSpace: expanded ? "normal" : "nowrap",
  textOverflow: "ellipsis",
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#3a3f47",
  },
}));

const Sidebar = () => {
  const [properties, setProperties] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [open, setOpen] = useState(false);
  const [newProperty, setNewProperty] = useState({ name: "", address: "" });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadProperties();
  }, []);

  const loadProperties = async () => {
    setLoading(true);
    try {
      const data = await getAllProperties();
      setProperties(data);
    } catch (error) {
      console.error("Failed to load properties:", error);
      alert("Failed to load properties. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handlePropertyClick = (property) => {
    navigate(`/dashboard/${property.property_id}`);
  };

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  const handleAddPropertyClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    setNewProperty({
      ...newProperty,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async () => {
    try {
      await createProperty(newProperty);
      setOpen(false);
      loadProperties();
    } catch (error) {
      console.error("Failed to add property:", error);
    }
  };

  const handleDeleteProperty = async (propertyId) => {
    try {
      await deleteProperty(propertyId);
      loadProperties(); // Reload properties after deletion
      navigate("/dashboard"); // Redirect to /dashboard after deletion
    } catch (error) {
      console.error("Failed to delete property:", error);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      // Redirect to the login page after successful logout
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error.message);
      alert("Failed to log out. Please try again.");
    }
  };

  return (
    <SidebarContainer
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(true)}
      expanded={expanded}
    >
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 2,
          cursor: "pointer",
        }}
        onClick={handleDashboardClick}
      >
        {expanded ? (
          <LogoWithText />
        ) : (
          <div className=" flex items-center justify-center">
            <img
              src="/assets/images/new_logo.png"
              className="m-2 rounded-md"
              alt="Maverick Logo"
              width={50}
              height={50}
            />
          </div>
        )}
      </Box>
      <Box sx={{ textAlign: "center", marginTop: 2, marginX: 1 }}>
        <Button
          variant="contained"
          onClick={handleAddPropertyClick}
          sx={{ width: "100%", backgroundColor: "#2C6EC3" }}
          startIcon={expanded ? <AddIcon /> : null}
        >
          {expanded ? "Add Property" : <AddIcon />}
        </Button>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <List sx={{ overflowY: "auto", flexGrow: 1 }}>
          {properties.map((property) => (
            <SidebarItem
              key={property.property_id}
              expanded={expanded}
              onClick={() => handlePropertyClick(property)}
            >
              <Box>
                <ListItemText
                  primary={property.name}
                  secondary={expanded ? property.address : null}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: "bold",
                      color: expanded ? "#ffffff" : "#ffffffcc",
                      fontFamily: "Inter",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: {
                      fontSize: "0.875rem",
                      color: "#ffffff99",
                      fontFamily: "Inter",
                    },
                  }}
                />
              </Box>
              {expanded && (
                <IconButton
                  onClick={() => handleDeleteProperty(property.property_id)}
                >
                  <DeleteIcon style={{ color: "#ffffff99" }} />
                </IconButton>
              )}
            </SidebarItem>
          ))}
        </List>
      )}

      <Box
        sx={{
          marginTop: "auto",
          textAlign: "center",
          paddingBottom: "16px",
          marginX: 1,
        }}
      >
        <Button
          variant="contained"
          onClick={handleLogout}
          sx={{
            backgroundColor: "#ff4444",
            color: "white",
            "&:hover": {
              backgroundColor: "#cc0000",
            },
            width: "100%",
            justifyContent: "center",
          }}
          startIcon={expanded ? <LogoutIcon /> : null}
        >
          {expanded ? "Logout" : <LogoutIcon />}
        </Button>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#282c34",
            color: "white",
          },
        }}
      >
        <DialogTitle
          sx={{ color: "#2761AB", fontWeight: "bold", mb: 2, mt: 1 }}
        >
          Add New Property
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Property Name"
            fullWidth
            variant="outlined"
            value={newProperty.name}
            onChange={handleInputChange}
            sx={{
              input: { color: "white" },
              label: { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "#2761AB",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#2761AB",
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 1000px #1a1a1a inset",
                  WebkitTextFillColor: "white",
                },
              },
            }}
          />
          <TextField
            margin="dense"
            name="address"
            label="Property Address"
            fullWidth
            variant="outlined"
            value={newProperty.address}
            onChange={handleInputChange}
            sx={{
              input: { color: "white" },
              label: { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "#2761AB",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#2761AB",
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 1000px #1a1a1a inset",
                  WebkitTextFillColor: "white",
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ marginX: 2, mb: 2 }}>
          <Button onClick={handleClose} sx={{ color: "white", mt: 1 }}>
            Cancel
          </Button>
          <Button
            onClick={handleFormSubmit}
            sx={{
              backgroundColor: "#2C6EC3",
              color: "white",
              "&:hover": {
                backgroundColor: "#2457A0",
              },
            }}
            color="primary"
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </SidebarContainer>
  );
};

export default Sidebar;
