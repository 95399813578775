import React from "react";
import { Box, Typography, Link } from "@mui/material";
import LogoWithText from "./LogoWithText";

const Footer = () => {
  return (
    <Box className="text-white py-4" sx={{ backgroundColor: "black" }}>
      <Box className="flex justify-between items-center mx-auto max-w-screen-xl px-4">
        <LogoWithText />
        <Box className="flex space-x-4">
          <Link
            href="#"
            color="inherit"
            underline="none"
            className="text-white"
          >
            Home
          </Link>
          <Link
            href="#"
            color="inherit"
            underline="none"
            className="text-white"
          >
            Features
          </Link>
          <Link
            href="#"
            color="inherit"
            underline="none"
            className="text-white"
          >
            Testimonials
          </Link>
          <Link
            href="#"
            color="inherit"
            underline="none"
            className="text-white"
          >
            FAQs
          </Link>
          <Link
            href="#"
            color="inherit"
            underline="none"
            className="text-white"
          >
            About us
          </Link>
          <Link
            href="#"
            color="inherit"
            underline="none"
            className="text-white"
          >
            Our Story
          </Link>
          <Link
            href="#"
            color="inherit"
            underline="none"
            className="text-white"
          >
            Our Work
          </Link>
          <Link
            href="#"
            color="inherit"
            underline="none"
            className="text-white"
          >
            How it works
          </Link>
        </Box>
        <Box className="flex space-x-4">
          <Link
            href="#"
            color="inherit"
            underline="none"
            className="text-white"
          >
            Our Team
          </Link>
          <Link
            href="#"
            color="inherit"
            underline="none"
            className="text-white"
          >
            Our Clients
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
