import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

const FeedbackDialog = ({ open, onClose }) => {
  const [name, setName] = useState("");
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    if (name && feedback) {
      onClose();
    } else {
      alert("Please fill in the required fields.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#141414",
          color: "white",
        },
      }}
    >
      <DialogTitle sx={{ color: "#2761AB", fontWeight: "bold", mb: 2 }}>
        Contact Us
        <DialogContentText variant="body2" sx={{ color: "white", mt: 1 }}>
          Please fill out the form below to get in touch with us.
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "#2761AB",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#2761AB",
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 1000px #1a1a1a inset",
                WebkitTextFillColor: "white",
              },
            },
          }}
        />
        <TextField
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "#2761AB",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#2761AB",
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 1000px #1a1a1a inset",
                WebkitTextFillColor: "white",
              },
            },
          }}
        />
        <TextField
          margin="dense"
          id="feedback"
          label="Feedback"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          required
          sx={{
            textarea: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "#2761AB",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#2761AB",
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 1000px #1a1a1a inset",
                WebkitTextFillColor: "white",
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            color: "white",
            borderColor: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              borderColor: "white",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#2C6EC3",
            color: "white",
            "&:hover": {
              backgroundColor: "#2457A0",
            },
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
