import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import LogoWithText from "./LogoWithText";
import FeedbackDialog from "./FeedbackDialog";
import AuthModal from "./AuthModal";

const TopNavBar = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleAuthModalOpen = () => {
    setAuthModalOpen(true);
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "black" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <LogoWithText />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button color="inherit" sx={{ color: "white" }}>
            Home
          </Button>
          <Button
            color="inherit"
            sx={{ color: "white" }}
            onClick={handleAuthModalOpen}
          >
            Login
          </Button>
          <Button color="inherit" sx={{ color: "white" }}>
            FAQ's
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            sx={{
              color: "white",
              borderColor: "white",
              "&:hover": {
                borderColor: "white",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
            }}
            onClick={handleDialogOpen}
          >
            Contact Us
          </Button>
        </Box>
      </Toolbar>
      <FeedbackDialog open={dialogOpen} onClose={handleDialogClose} />
      <AuthModal open={authModalOpen} onClose={handleAuthModalClose} />
    </AppBar>
  );
};

export default TopNavBar;
