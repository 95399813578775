import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import ChatPage from "./pages/ChatPage";
import Dashboard from "./pages/Dashboard";
import PropertyDashboard from "./components/PropertyDashboard";
import DocumentAnalysis from "./components/DocumentViewer";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path=":property_id" element={<PropertyDashboard />} />
          <Route
            path=":property_id/:document_id/analysis"
            element={<DocumentAnalysis />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
