import React, { useState } from "react";
import TopNavBar from "../components/TopNavBar";
import { Button } from "@mui/material";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import AuthModal from "../components/AuthModal";
import { useAuth } from "../hooks/useAuth";

function LandingPage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const handleAuthModalOpen = () => {
    if (isAuthenticated) {
      navigate("/dashboard");
    } else {
      setAuthModalOpen(true);
    }
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };
  const how_it_works = [
    {
      number: 1,
      title: "Effortless PDF Upload",
      description: "Upload your document and done. It’s that easy.",
    },
    {
      number: 2,
      title: "AI-Powered Summary Reading",
      description:
        "Effortlessly comprehend summaries with the precision of AI, enhancing efficiency and understanding instantly",
    },
    {
      number: 3,
      title: "Converse with Your Compliance",
      description:
        "Efficiently interact with AI for swift, accurate disclosure management.",
    },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <TopNavBar />
      <div className="flex-grow flex flex-col">
        <div className="flex flex-row w-full">
          <div className="p-16 font-sans justify-center bg-black w-full min-w-[50%]">
            <h1 className="text-white text-[42px] leading-tight py-4">
              <span className="text-[#2761AB]">AI-Driven</span>
              &nbsp;Real Estate Solutions
            </h1>
            <p className="text-white">
              Your Trust, Our Priority. Seamlessly automate real estate
              disclosure forms with advanced AI, ensuring accuracy and saving
              time.
            </p>
            <div className="py-7 flex flex-row">
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "transparent",
                  borderColor: "#1F1F1F",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderRadius: "5px",
                  padding: "10px",
                  fontSize: "12px",
                  marginRight: "20px",
                  fontFamily: "Inter",
                }}
                onClick={() => navigate("/chat")}
              >
                Get Started
              </Button>
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#2C6EC3",
                  padding: "10px 50px",
                  fontSize: "12px",
                  fontFamily: "Inter",
                  "&:hover": {
                    backgroundColor: "#2C6EC3",
                  },
                }}
                onClick={handleAuthModalOpen}
              >
                Login
              </Button>
            </div>
          </div>
          <div className="bg-[#1A1A1A]">
            <img
              src="/assets/images/landing-home.png"
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="w-full bg-black p-10">
          <h1 className="font-sans text-[#2C6EC3] text-[34px] text-center pb-4">
            How it works?
          </h1>
          <div className="justify-center flex flex-row">
            {how_it_works.map((item) => (
              <div className="flex flex-col rounded-2xl bg-[#1A1A1A] p-6 m-4 w-96 justify-center">
                <div className="rounded-full h-6 w-6 text-[#1A1A1A] bg-[#2C6EC3] text-center mx-auto">
                  {item.number}
                </div>
                <p className="text-white text-[16px] font-sans py-2 mx-auto">
                  {item.title}
                </p>
                <p className="text-white text-[10px] font-sans text-center">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <AuthModal open={authModalOpen} onClose={handleAuthModalClose} />
    </div>
  );
}

export default LandingPage;
