import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { supabase, setToken } from "../utils/auth";
import { useAuth } from "../hooks/useAuth";
import { createUser } from "../utils/api";

const AuthModal = ({ open, onClose }) => {
  const { setIsAuthenticated } = useAuth();
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (isLogin) {
      // Login logic
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        alert("Error logging in: " + error.message);
      } else {
        setToken(data.session.access_token);
        await createUser({ email, name });
        setIsAuthenticated(true);
        onClose(); // Close modal after successful login
        navigate("/dashboard"); // Redirect to dashboard
      }
    } else {
      // Signup logic
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            full_name: name,
          },
        },
      });

      if (error) {
        alert("Error signing up: " + error.message);
      } else {
        alert(
          "Signup successful! Please check your email to verify your account."
        );
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#141414",
          color: "white",
        },
      }}
    >
      <DialogTitle sx={{ color: "#2761AB", fontWeight: "bold", mb: 2, mt: 1 }}>
        {isLogin ? "Login" : "Sign Up"}
        <DialogContentText variant="body2" sx={{ color: "white", mt: 1 }}>
          {isLogin
            ? "Please enter your credentials to log in."
            : "Please fill out the form below to create an account."}
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        {!isLogin && (
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            sx={{
              input: { color: "white" },
              label: { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "#2761AB",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#2761AB",
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 1000px #1a1a1a inset",
                  WebkitTextFillColor: "white",
                },
              },
            }}
          />
        )}
        <TextField
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "#2761AB",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#2761AB",
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 1000px #1a1a1a inset",
                WebkitTextFillColor: "white",
              },
            },
          }}
        />
        <TextField
          margin="dense"
          id="password"
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "#2761AB",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#2761AB",
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 1000px #1a1a1a inset",
                WebkitTextFillColor: "white",
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ marginX: 2 }}>
        <Button
          onClick={onClose}
          sx={{
            color: "white",
            borderColor: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              borderColor: "white",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#2C6EC3",
            color: "white",
            "&:hover": {
              backgroundColor: "#2457A0",
            },
          }}
        >
          {isLogin ? "Log In" : "Sign Up"}
        </Button>
      </DialogActions>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => setIsLogin(!isLogin)}
          sx={{ color: "#2761AB", marginX: 1 }}
        >
          {isLogin
            ? "Don't have an account? Sign Up"
            : "Already have an account? Log In"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthModal;
